import React, { useState, useEffect, Fragment } from 'react'
import Select from 'react-select'
import { MdDoneOutline } from 'react-icons/md'

import './bridge-style.css'
import { Modal, Button } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { SiBinance } from 'react-icons/si'
import { FaEthereum } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import Web3 from 'web3'
import chain from '../../src/utils/networks'
import ABI from '../utils/ritz-abi.json'
import axios from 'axios'
import { Link } from 'react-router-dom'
const Bridge = props => {
  const [tokVal, setTokenVal] = useState(0)
  const [cookies, setCookie] = useCookies(['address'])
  const [wallAd, setWall] = useState('')
  const [err, setError] = useState('')
  const [show, setShow] = useState(false)
  const [showsuccess, setSuccess] = useState(false)
  const [showLoad, setLoad] = useState(false)
  const [bal, setBal] = useState(0)
  const [userId, setUserId] = useState('')
  useEffect(() => {
    console.log(cookies)
    if (cookies.address != undefined) {
      setWall(cookies)
    }
  }, [cookies])

  const [chain, setChain] = useState('')
  const InputHandler = e => {
    setTokenVal(e.target.value)
  }
  const options = [
    {
      value: 'statusone',
      label: (
        <div>
          <svg
            viewBox='0 0 24 24'
            width='20px'
            color='textSubtle'
            xmlns='http://www.w3.org/2000/svg'
            className='sc-bdnxRM fEEbyN'
          >
            <path d='M11.995 3.027v.009c.01.044.01.098.01.152v6.441c-.01.036-.037.045-.063.063-.188.09-.367.17-.554.25-.26.116-.527.241-.787.357l-.938.43c-.259.115-.518.232-.768.348-.304.143-.616.277-.92.42-.26.116-.518.24-.786.357-.215.098-.43.188-.635.286-.018.009-.035.018-.053.018-.01 0-.01 0-.018-.01l.241-.401c.465-.769.92-1.528 1.385-2.296.491-.822.992-1.644 1.483-2.466.456-.76.92-1.52 1.376-2.279.33-.553.67-1.107 1-1.661.01-.018.018-.027.018-.045h.01c-.01.009 0 .018 0 .027z'></path>
            <path
              d='M17.481 12.149l.01.009-1.314.777-4.128 2.44a.196.196 0 00-.044.026c-.027 0-.027-.027-.027-.036V9.763c0-.027 0-.062.009-.09.009-.035.035-.026.062-.017.116.054.241.107.358.16.348.162.697.322 1.045.474.304.134.598.277.902.411.304.134.608.277.912.411.259.117.527.242.786.358.259.116.527.241.786.357.206.09.411.188.616.277 0 .027.01.036.027.045z'
              fillOpacity='0.55'
            ></path>
            <path d='M11.996 20.95c0 .008-.01.017-.01.026h-.008c0-.018-.018-.026-.027-.044-.554-.778-1.108-1.564-1.662-2.341l-1.697-2.395c-.545-.768-1.1-1.545-1.644-2.314-.143-.205-.286-.402-.43-.607-.008-.018-.017-.027-.035-.054.027 0 .045.018.054.027.777.456 1.545.911 2.323 1.367.893.527 1.778 1.054 2.671 1.581l.456.268c.018.018.018.036.018.054v4.306c0 .045 0 .09-.01.125z'></path>
            <path
              d='M6.474 12.167v-.01c.286-.124.563-.258.849-.383.366-.17.733-.33 1.099-.5.277-.126.563-.26.84-.385.41-.187.813-.366 1.224-.554.277-.125.554-.25.84-.384.196-.09.401-.179.598-.268.018-.009.045-.018.054-.036.009 0 .009.01 0 .018v5.674c0 .026-.01.053.009.071-.018.027-.036 0-.045-.009-.08-.044-.16-.09-.241-.143-1.725-1.018-3.458-2.046-5.182-3.064-.01-.01-.027-.018-.045-.027z'
              fillOpacity='0.78'
            ></path>
            <path
              d='M17.464 13.221h.009c0 .018-.018.036-.027.054-1.644 2.314-3.288 4.637-4.932 6.95-.17.242-.348.483-.518.724-.01-.008-.01-.017-.01-.026V16.456c.376-.224.742-.438 1.118-.662 1.447-.857 2.894-1.706 4.333-2.564.009.01.018 0 .027-.009zM11.986 9.665V3.107c0-.027-.008-.045.01-.071 1.813 3.01 3.627 6.012 5.431 9.023.018.027.045.063.054.099-.125-.045-.241-.107-.357-.161-.143-.063-.295-.134-.438-.197-.09-.044-.188-.08-.277-.125-.152-.071-.304-.134-.456-.205-.089-.036-.178-.08-.268-.125l-.59-.268a10.23 10.23 0 00-.303-.134l-.429-.188a3.636 3.636 0 01-.268-.125l-.59-.268c-.098-.045-.196-.09-.303-.134l-.43-.188c-.098-.044-.187-.09-.285-.134-.17-.08-.34-.16-.518-.232.026-.009.018-.009.018-.009z'
              fillOpacity='0.75'
            ></path>
          </svg>
          Ethereum
        </div>
      )
    },
    {
      value: 'statusthree',
      label: (
        <div>
          <svg
            viewBox='0 0 24 24'
            width='20px'
            color='textSubtle'
            xmlns='http://www.w3.org/2000/svg'
            className='sc-bdnxRM fEEbyN'
          >
            <path d='M8.504 10.564L12 7.068l3.498 3.497 2.034-2.034L12 3 6.47 8.53l2.034 2.034zM3 12l2.034-2.034L7.068 12l-2.034 2.034L3 12zm5.504 1.437L12 16.931l3.497-3.497 2.035 2.033L12 21l-5.53-5.53-.002-.002 2.037-2.032zM16.932 12l2.034-2.034L21 12l-2.034 2.034L16.932 12z'></path>
            <path d='M14.063 11.999L12 9.935l-1.526 1.525-.175.175-.361.362-.003.003.003.003L12 14.065l2.064-2.064V12l-.001-.001'></path>
          </svg>
          BSC
        </div>
      )
    }
  ]
  const options2 = [
    {
      value: 'statusone',
      label: (
        <div>
          <svg
            viewBox='0 0 24 24'
            width='20px'
            color='textSubtle'
            xmlns='http://www.w3.org/2000/svg'
            className='sc-bdnxRM fEEbyN'
          >
            <path d='M11.995 3.027v.009c.01.044.01.098.01.152v6.441c-.01.036-.037.045-.063.063-.188.09-.367.17-.554.25-.26.116-.527.241-.787.357l-.938.43c-.259.115-.518.232-.768.348-.304.143-.616.277-.92.42-.26.116-.518.24-.786.357-.215.098-.43.188-.635.286-.018.009-.035.018-.053.018-.01 0-.01 0-.018-.01l.241-.401c.465-.769.92-1.528 1.385-2.296.491-.822.992-1.644 1.483-2.466.456-.76.92-1.52 1.376-2.279.33-.553.67-1.107 1-1.661.01-.018.018-.027.018-.045h.01c-.01.009 0 .018 0 .027z'></path>
            <path
              d='M17.481 12.149l.01.009-1.314.777-4.128 2.44a.196.196 0 00-.044.026c-.027 0-.027-.027-.027-.036V9.763c0-.027 0-.062.009-.09.009-.035.035-.026.062-.017.116.054.241.107.358.16.348.162.697.322 1.045.474.304.134.598.277.902.411.304.134.608.277.912.411.259.117.527.242.786.358.259.116.527.241.786.357.206.09.411.188.616.277 0 .027.01.036.027.045z'
              fillOpacity='0.55'
            ></path>
            <path d='M11.996 20.95c0 .008-.01.017-.01.026h-.008c0-.018-.018-.026-.027-.044-.554-.778-1.108-1.564-1.662-2.341l-1.697-2.395c-.545-.768-1.1-1.545-1.644-2.314-.143-.205-.286-.402-.43-.607-.008-.018-.017-.027-.035-.054.027 0 .045.018.054.027.777.456 1.545.911 2.323 1.367.893.527 1.778 1.054 2.671 1.581l.456.268c.018.018.018.036.018.054v4.306c0 .045 0 .09-.01.125z'></path>
            <path
              d='M6.474 12.167v-.01c.286-.124.563-.258.849-.383.366-.17.733-.33 1.099-.5.277-.126.563-.26.84-.385.41-.187.813-.366 1.224-.554.277-.125.554-.25.84-.384.196-.09.401-.179.598-.268.018-.009.045-.018.054-.036.009 0 .009.01 0 .018v5.674c0 .026-.01.053.009.071-.018.027-.036 0-.045-.009-.08-.044-.16-.09-.241-.143-1.725-1.018-3.458-2.046-5.182-3.064-.01-.01-.027-.018-.045-.027z'
              fillOpacity='0.78'
            ></path>
            <path
              d='M17.464 13.221h.009c0 .018-.018.036-.027.054-1.644 2.314-3.288 4.637-4.932 6.95-.17.242-.348.483-.518.724-.01-.008-.01-.017-.01-.026V16.456c.376-.224.742-.438 1.118-.662 1.447-.857 2.894-1.706 4.333-2.564.009.01.018 0 .027-.009zM11.986 9.665V3.107c0-.027-.008-.045.01-.071 1.813 3.01 3.627 6.012 5.431 9.023.018.027.045.063.054.099-.125-.045-.241-.107-.357-.161-.143-.063-.295-.134-.438-.197-.09-.044-.188-.08-.277-.125-.152-.071-.304-.134-.456-.205-.089-.036-.178-.08-.268-.125l-.59-.268a10.23 10.23 0 00-.303-.134l-.429-.188a3.636 3.636 0 01-.268-.125l-.59-.268c-.098-.045-.196-.09-.303-.134l-.43-.188c-.098-.044-.187-.09-.285-.134-.17-.08-.34-.16-.518-.232.026-.009.018-.009.018-.009z'
              fillOpacity='0.75'
            ></path>
          </svg>
          Ethereum
        </div>
      )
    },
    {
      value: 'statusthree',
      label: (
        <div>
          <svg
            viewBox='0 0 24 24'
            width='20px'
            color='textSubtle'
            xmlns='http://www.w3.org/2000/svg'
            className='sc-bdnxRM fEEbyN'
          >
            <path d='M8.504 10.564L12 7.068l3.498 3.497 2.034-2.034L12 3 6.47 8.53l2.034 2.034zM3 12l2.034-2.034L7.068 12l-2.034 2.034L3 12zm5.504 1.437L12 16.931l3.497-3.497 2.035 2.033L12 21l-5.53-5.53-.002-.002 2.037-2.032zM16.932 12l2.034-2.034L21 12l-2.034 2.034L16.932 12z'></path>
            <path d='M14.063 11.999L12 9.935l-1.526 1.525-.175.175-.361.362-.003.003.003.003L12 14.065l2.064-2.064V12l-.001-.001'></path>
          </svg>
          BSC
        </div>
      )
    }
  ]

  //   handle1->statusone;
  //   status3->statusthree;

  const [wallAddress, setWallAdress] = useState('')

  const ChangeChain = async arg => {
    let ethereum = window.ethereum
    const data = [
      {
        chainId: '0x61',
        chainName: 'Smart Chain - Testnet',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com']
      }
    ]
    const data2 = [
      {
        chainName: 'Ethereum Testnet Rinkeby',

        rpcUrls: [
          'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
        ],
        nativeCurrency: { name: 'Rinkeby Ether', symbol: 'RIN', decimals: 18 },
        chainId: '0x4',
        blockExplorerUrls: ['https://rinkeby.etherscan.io']
      }
    ]

    //     /* eslint-disable */
    if (arg == 'statusone') {
      const tx2 = await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x4' }]
      })
      if (tx2) {
        return tx2
      }
    } else {
      const tx = await ethereum
        .request({ method: 'wallet_addEthereumChain', params: data })
        .catch()
      if (tx) {
        return tx
      }
    }
  }
  const ConnectWallet = async arg => {
    ChangeChain(arg)

    const provider = window.ethereum
    const web3 = new Web3(provider)
    var accounts = await web3.eth.getAccounts()

    console.log(accounts)
    setWall(accounts[0])
    const balan = await web3.eth.getBalance(accounts[0])
    setCookie('address', accounts[0])
    const jsonAbi = ABI
    const contractAddress = '0x8c3294a3b3638eeb7526592ecca6234fde344742' // address of the token contract
    const tokenAddress = '0xDD4e4388c4C8DF07Bac11bCFD1657F5678dDcC0B'
    const token = new web3.eth.Contract(jsonAbi, contractAddress)

    const balance = await token.methods.balanceOf(tokenAddress).call()
    const bal = web3.utils.fromWei(balance)

    setBal(bal)
  }

  const [selectedOption, setSelectedOption] = useState('')

  const [selectedOption2, setSelectedOption2] = useState('')
  const BridgeTransAction = async () => {
    setShow(true)
    setLoad(true)
    console.log(selectedOption)

    var network1, network2
    var Tx = require('ethereumjs-tx')
    if (selectedOption.value == 'statusone') {
      network1 = 'ETH'
      network2 = 'BSC'
    } else {
      network1 = 'BSC'
      network2 = 'ETH'
    }

    //get my accounts
    const provider = window.ethereum
    const web3 = new Web3(provider)
    // const signer = provider.getSigner();
    var senderaccount = await web3.eth.getAccounts()
    console.log(network1, network2)
    console.log(senderaccount[0])
    // var tx = require('ethereumjs-tx')

    var myAddress = senderaccount[0]

    // // Who are we trying to send this token to?
    //it should be in env
    var destAddress = '0x3EBd245c9c00D0BA1f03bb915AEDcd3246A9D900'

    //amount we input in our input field
    var transferAmount = tokVal
    var contractAddress = '0x8c3294a3b3638eeB7526592EccA6234FDE344742'
    var contract = new web3.eth.Contract(ABI, contractAddress, {
      from: myAddress
    })

    // var valueToBridge = (transferAmount * 0.1) / 100

    await contract.methods
      .transfer(destAddress, web3.utils.toWei(transferAmount))
      .send({
        from: myAddress
      })
      .then(res => {
        var req = {
          senderNetwork: network1,
          receiverNetwork: network2,
          fromWalletAddress: myAddress,
          toWalletAddress: destAddress,
          sourceHash: res.transactionHash,
          sendValue: transferAmount
        }
        axios
          .post('http://18.118.82.246:3000/v1/users', req)
          .then(res => {
            console.log(res.data.userId)
            setUserId(res.data.userId)
            setCookie('userId', res.data.userId)
            setSuccess(true)
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })

    // web3.eth.getTransactionReceipt(this.state.transactionHash, (err, txReceipt)=>{
    //   console.log(err,txReceipt);
    //   this.setState({txReceipt});
    //  })
  }
  const handleModalClose = () => setShow(false)

  const ModalHandle = () => {
    // BridgeTransAction();
    if (Number(tokVal) <= 0 || Number(tokVal) > Number(bal)) {
      setError('!!! Check Your Input or Network Selection')
    } else {

      setError('')
      setShow(true)
      setLoad(false);

    }
  }
  const handleChange = async selectedOption => {
    if (selectedOption.value == 'statusone') {
      ConnectWallet('statusone') //choose chain

      let val2 = {
        value: 'statusthree',
        label: (
          <div>
            <svg
              viewBox='0 0 24 24'
              width='20px'
              color='textSubtle'
              xmlns='http://www.w3.org/2000/svg'
              className='sc-bdnxRM fEEbyN'
            >
              <path d='M8.504 10.564L12 7.068l3.498 3.497 2.034-2.034L12 3 6.47 8.53l2.034 2.034zM3 12l2.034-2.034L7.068 12l-2.034 2.034L3 12zm5.504 1.437L12 16.931l3.497-3.497 2.035 2.033L12 21l-5.53-5.53-.002-.002 2.037-2.032zM16.932 12l2.034-2.034L21 12l-2.034 2.034L16.932 12z'></path>
              <path d='M14.063 11.999L12 9.935l-1.526 1.525-.175.175-.361.362-.003.003.003.003L12 14.065l2.064-2.064V12l-.001-.001'></path>
            </svg>
            BSC
          </div>
        )
      }
      setSelectedOption2(val2)
      setSelectedOption(selectedOption)
    } else {
      ConnectWallet('statusthree') //choose chain

      let val = {
        value: 'statusone',
        label: (
          <div>
            <svg
              viewBox='0 0 24 24'
              width='20px'
              color='textSubtle'
              xmlns='http://www.w3.org/2000/svg'
              className='sc-bdnxRM fEEbyN'
            >
              <path d='M11.995 3.027v.009c.01.044.01.098.01.152v6.441c-.01.036-.037.045-.063.063-.188.09-.367.17-.554.25-.26.116-.527.241-.787.357l-.938.43c-.259.115-.518.232-.768.348-.304.143-.616.277-.92.42-.26.116-.518.24-.786.357-.215.098-.43.188-.635.286-.018.009-.035.018-.053.018-.01 0-.01 0-.018-.01l.241-.401c.465-.769.92-1.528 1.385-2.296.491-.822.992-1.644 1.483-2.466.456-.76.92-1.52 1.376-2.279.33-.553.67-1.107 1-1.661.01-.018.018-.027.018-.045h.01c-.01.009 0 .018 0 .027z'></path>
              <path
                d='M17.481 12.149l.01.009-1.314.777-4.128 2.44a.196.196 0 00-.044.026c-.027 0-.027-.027-.027-.036V9.763c0-.027 0-.062.009-.09.009-.035.035-.026.062-.017.116.054.241.107.358.16.348.162.697.322 1.045.474.304.134.598.277.902.411.304.134.608.277.912.411.259.117.527.242.786.358.259.116.527.241.786.357.206.09.411.188.616.277 0 .027.01.036.027.045z'
                fillOpacity='0.55'
              ></path>
              <path d='M11.996 20.95c0 .008-.01.017-.01.026h-.008c0-.018-.018-.026-.027-.044-.554-.778-1.108-1.564-1.662-2.341l-1.697-2.395c-.545-.768-1.1-1.545-1.644-2.314-.143-.205-.286-.402-.43-.607-.008-.018-.017-.027-.035-.054.027 0 .045.018.054.027.777.456 1.545.911 2.323 1.367.893.527 1.778 1.054 2.671 1.581l.456.268c.018.018.018.036.018.054v4.306c0 .045 0 .09-.01.125z'></path>
              <path
                d='M6.474 12.167v-.01c.286-.124.563-.258.849-.383.366-.17.733-.33 1.099-.5.277-.126.563-.26.84-.385.41-.187.813-.366 1.224-.554.277-.125.554-.25.84-.384.196-.09.401-.179.598-.268.018-.009.045-.018.054-.036.009 0 .009.01 0 .018v5.674c0 .026-.01.053.009.071-.018.027-.036 0-.045-.009-.08-.044-.16-.09-.241-.143-1.725-1.018-3.458-2.046-5.182-3.064-.01-.01-.027-.018-.045-.027z'
                fillOpacity='0.78'
              ></path>
              <path
                d='M17.464 13.221h.009c0 .018-.018.036-.027.054-1.644 2.314-3.288 4.637-4.932 6.95-.17.242-.348.483-.518.724-.01-.008-.01-.017-.01-.026V16.456c.376-.224.742-.438 1.118-.662 1.447-.857 2.894-1.706 4.333-2.564.009.01.018 0 .027-.009zM11.986 9.665V3.107c0-.027-.008-.045.01-.071 1.813 3.01 3.627 6.012 5.431 9.023.018.027.045.063.054.099-.125-.045-.241-.107-.357-.161-.143-.063-.295-.134-.438-.197-.09-.044-.188-.08-.277-.125-.152-.071-.304-.134-.456-.205-.089-.036-.178-.08-.268-.125l-.59-.268a10.23 10.23 0 00-.303-.134l-.429-.188a3.636 3.636 0 01-.268-.125l-.59-.268c-.098-.045-.196-.09-.303-.134l-.43-.188c-.098-.044-.187-.09-.285-.134-.17-.08-.34-.16-.518-.232.026-.009.018-.009.018-.009z'
                fillOpacity='0.75'
              ></path>
            </svg>
            Ethereum
          </div>
        )
      }

      setSelectedOption2(val)
      setSelectedOption(selectedOption)
    }
  }
  const handleChange2 = arg => {
    if (arg.value == 'statusone') {
      ConnectWallet('statusone') //choose chain

      let val2 = {
        value: 'statusthree',
        label: (
          <div>
            <svg
              viewBox='0 0 24 24'
              width='20px'
              color='textSubtle'
              xmlns='http://www.w3.org/2000/svg'
              className='sc-bdnxRM fEEbyN'
            >
              <path d='M8.504 10.564L12 7.068l3.498 3.497 2.034-2.034L12 3 6.47 8.53l2.034 2.034zM3 12l2.034-2.034L7.068 12l-2.034 2.034L3 12zm5.504 1.437L12 16.931l3.497-3.497 2.035 2.033L12 21l-5.53-5.53-.002-.002 2.037-2.032zM16.932 12l2.034-2.034L21 12l-2.034 2.034L16.932 12z'></path>
              <path d='M14.063 11.999L12 9.935l-1.526 1.525-.175.175-.361.362-.003.003.003.003L12 14.065l2.064-2.064V12l-.001-.001'></path>
            </svg>
            BSC
          </div>
        )
      }
      setSelectedOption(val2)
      setSelectedOption2(arg)
    } else {
      ConnectWallet('statusthree') //choose chain

      let val = {
        value: 'statusone',
        label: (
          <div>
            <svg
              viewBox='0 0 24 24'
              width='20px'
              color='textSubtle'
              xmlns='http://www.w3.org/2000/svg'
              className='sc-bdnxRM fEEbyN'
            >
              <path d='M11.995 3.027v.009c.01.044.01.098.01.152v6.441c-.01.036-.037.045-.063.063-.188.09-.367.17-.554.25-.26.116-.527.241-.787.357l-.938.43c-.259.115-.518.232-.768.348-.304.143-.616.277-.92.42-.26.116-.518.24-.786.357-.215.098-.43.188-.635.286-.018.009-.035.018-.053.018-.01 0-.01 0-.018-.01l.241-.401c.465-.769.92-1.528 1.385-2.296.491-.822.992-1.644 1.483-2.466.456-.76.92-1.52 1.376-2.279.33-.553.67-1.107 1-1.661.01-.018.018-.027.018-.045h.01c-.01.009 0 .018 0 .027z'></path>
              <path
                d='M17.481 12.149l.01.009-1.314.777-4.128 2.44a.196.196 0 00-.044.026c-.027 0-.027-.027-.027-.036V9.763c0-.027 0-.062.009-.09.009-.035.035-.026.062-.017.116.054.241.107.358.16.348.162.697.322 1.045.474.304.134.598.277.902.411.304.134.608.277.912.411.259.117.527.242.786.358.259.116.527.241.786.357.206.09.411.188.616.277 0 .027.01.036.027.045z'
                fillOpacity='0.55'
              ></path>
              <path d='M11.996 20.95c0 .008-.01.017-.01.026h-.008c0-.018-.018-.026-.027-.044-.554-.778-1.108-1.564-1.662-2.341l-1.697-2.395c-.545-.768-1.1-1.545-1.644-2.314-.143-.205-.286-.402-.43-.607-.008-.018-.017-.027-.035-.054.027 0 .045.018.054.027.777.456 1.545.911 2.323 1.367.893.527 1.778 1.054 2.671 1.581l.456.268c.018.018.018.036.018.054v4.306c0 .045 0 .09-.01.125z'></path>
              <path
                d='M6.474 12.167v-.01c.286-.124.563-.258.849-.383.366-.17.733-.33 1.099-.5.277-.126.563-.26.84-.385.41-.187.813-.366 1.224-.554.277-.125.554-.25.84-.384.196-.09.401-.179.598-.268.018-.009.045-.018.054-.036.009 0 .009.01 0 .018v5.674c0 .026-.01.053.009.071-.018.027-.036 0-.045-.009-.08-.044-.16-.09-.241-.143-1.725-1.018-3.458-2.046-5.182-3.064-.01-.01-.027-.018-.045-.027z'
                fillOpacity='0.78'
              ></path>
              <path
                d='M17.464 13.221h.009c0 .018-.018.036-.027.054-1.644 2.314-3.288 4.637-4.932 6.95-.17.242-.348.483-.518.724-.01-.008-.01-.017-.01-.026V16.456c.376-.224.742-.438 1.118-.662 1.447-.857 2.894-1.706 4.333-2.564.009.01.018 0 .027-.009zM11.986 9.665V3.107c0-.027-.008-.045.01-.071 1.813 3.01 3.627 6.012 5.431 9.023.018.027.045.063.054.099-.125-.045-.241-.107-.357-.161-.143-.063-.295-.134-.438-.197-.09-.044-.188-.08-.277-.125-.152-.071-.304-.134-.456-.205-.089-.036-.178-.08-.268-.125l-.59-.268a10.23 10.23 0 00-.303-.134l-.429-.188a3.636 3.636 0 01-.268-.125l-.59-.268c-.098-.045-.196-.09-.303-.134l-.43-.188c-.098-.044-.187-.09-.285-.134-.17-.08-.34-.16-.518-.232.026-.009.018-.009.018-.009z'
                fillOpacity='0.75'
              ></path>
            </svg>
            Ethereum
          </div>
        )
      }
      setSelectedOption(val)
      setSelectedOption2(arg)
    }
  }
  // console.log(selectedOption2)
  return (
    <Fragment>
      <div className='bridge'>
        <div className='form-wrapper'>
          <form>
            <fieldset>
              <legend>
                Bridge my Ritz tokens
              </legend>
              <div className='form-content'>
                <div className='form-top-wrap'>
                  <div className='box-holder'>
                    <h2>From Chain</h2>
                    {selectedOption.value == 'statusone' ? (
                      <FaEthereum color='grey' size='40px' />
                    ) : (
                      <SiBinance color='yellow' size='40px' />
                    )}
                    <Select
                      onChange={e => handleChange(e)}
                      options={options}
                      value={selectedOption}
                      classNamePrefix='react-select'
                    />
                  </div>
                  <div className='box-holder'>
                    <button className='arrow-btn'>
                      <svg
                        viewBox='0 0 24 24'
                        color='text'
                        width='24px'
                        xmlns='http://www.w3.org/2000/svg'
                        className='arrow-icon sc-bdnxRM iSlpOE'
                      >
                        <path d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z'></path>
                      </svg>
                    </button>
                  </div>

                  <div className='box-holder'>
                    <h2>To Chain</h2>
                    {selectedOption2.value == 'statusthree' ? (
                      <SiBinance color='yellow' size='40px' />
                    ) : (
                      <FaEthereum color='lightgrey' size='40px' />
                    )}

                    <Select
                      onChange={e => handleChange2(e)}
                      options={options2}
                      value={selectedOption2}
                      classNamePrefix='react-select'
                    />
                  </div>
                </div>

                <div className='form-middle-wrap'>
                  <h6>Token to Bridge</h6>
                  <div className='token-input-wrap'>
                    <input
                      className='token-amount-input'
                      type='number'
                      pattern='^[0-9]*[.,]?[0-9]*$'
                      value={tokVal}
                      onChange={InputHandler}
                      placeholder='0.0'
                    ></input>
                  </div>

                  <h6 className='border-line'>Balance on RITZ</h6>
                  <h6>{bal}</h6>
                </div>
                <div className='form-bottom-wrap text-center'>
                  {cookies.address == undefined ? (
                    <>
                      <p>
                        • Please connect your wallet to the chain you wish to
                        bridge from!
                        <a
                          href='#'
                          className='switch-text'
                          height='14px'
                          scale='sm'
                        >
                          Click Here to Switch
                        </a>
                      </p>
                      <button
                        type='button'
                        className='btn btn btn-danger'
                        onClick={ConnectWallet}
                      >
                        <img
                          src='/images/menu/connect-wallet.svg'
                          alt='Wallet Icon'
                          className='wallet-icon'
                        />
                        <span>Connect Wallet</span>
                      </button>
                    </>
                  ) : (
                    <button
                      type='button'
                      className='btn btn btn-danger m-4'
                      onClick={ModalHandle}
                    >
                      <img
                        src='/images/menu/connect-wallet.svg'
                        alt='Wallet Icon'
                        className='wallet-icon'
                      />
                      <span>Bridge</span>
                    </button>
                  )}
                </div>
                <div className='text-danger m-4'>{err}</div>

                <div className='enter-dApp-button d-flex align-items-center justify-content-center mb-2'></div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      <Modal show={show} onHide={handleModalClose} className='bridge-modal' backdrop="static" keyboard={false}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className='content-wrap'>
            <p>
              You are sending{' '}
              <span className='text-primary'>{tokVal} Ritz</span>{' '}
            </p>
            <h1>Click Confirm to Proceed </h1>
          </div>

          <div className='checkmark'>
            {showLoad == true && showsuccess == false ? (
              <>
                <svg
                  version='1.1'
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  xmlSpace='preserve'
                  x='0px'
                  y='0px'
                  viewBox='0, 0, 100, 100'
                  id='checkmark'
                >
                  <g transform=''>
                    <circle
                      id='path'
                      className='path'
                      fill='none'
                      stroke='#7DB0D5'
                      strokeWidth='4'
                      strokeMiterlimit='10'
                      cx='50'
                      cy='50'
                      r='44'
                    />
                    <circle
                      id='fill'
                      className='fill'
                      fill='none'
                      stroke='#7DB0D5'
                      strokeWidth='4'
                      strokeMiterlimit='10'
                      cx='50'
                      cy='50'
                      r='44'
                    />
                  </g>
                </svg>
              </>
            ) : (
              <></>
            )}
            {showsuccess == true ? (
              <>
                <div className='mb-2 text-center check-icon'>
                  {/* <MdDoneOutline size='50px' color='green' /> */}
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <div style={{width: '14rem'}}>
                  {' '}
                  <h6>
                    <Link to={`bridge-detail/${userId}`}>
                      {' '}
                      Trace Your Transactions
                    </Link>
                  </h6>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>
        {/* CLOSE BUTTON REMOVE ON LOADING */}
        {showLoad !== true ? (
          <Modal.Footer>
            <div className='enter-dApp-button'>
              <Button variant='primary' onClick={BridgeTransAction}>
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Modal>
    </Fragment>
  )
}

export default Bridge
